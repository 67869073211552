@import "../../styles/vars";

.box {
  transition: ease-in-out 0.2s;
  position: relative;

  .cardLink {
    position: absolute;
    inset: 0;
  }
}
.box:hover {
  background: #f5f5f5;
  border-radius: 24px;
}
.cta {
  background: $accentColor;
  border: 2px solid $borderColor;
  border-radius: 12px;
  padding: 5px 24px;
  text-align: center;
  color: $mainColor;
}
.categoria {
  margin-bottom: 15px;
}
.categoria a {
  color: $defaultColorLight;
  text-transform: uppercase;
  font-size: 0.85em;
  border-radius: 5px;
  padding: 5px 10px;
  position: relative;
  z-index: 1;
}

.texto {
  h2 {
    font-size: 2em;
    color: $defaultColor;
    font-weight: 900;
    margin-bottom: 20px;
  }
  p {
    color: $defaultColorLight;
    margin-right: 20px;
  }
}
.info {
  color: $mainColor;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 20px;
  }
  p {
    margin-bottom: 0;
    font-size: 0.85em;
    color: $mainColor;
  }
  .autor {
    margin-right: 5px;
  }
  .autor p {
    text-decoration: underline;
  }
  .dataPostagem {
    margin-left: 5px;
  }
}
.boxSecond {
  &:hover {
    background: #f5f5f5;
    border-radius: 24px;
  }
  cursor: pointer;
  h2 {
    transition: ease-in-out 0.2s;
  }
  &:hover h2 {
    color: $mainColor;
  }
  .thumbBoxSecond {
    border-radius: 12px;
  }
  @media (max-width: 480px) {
    padding: 24px !important;
    p {
      margin: 0;
    }
  }
}
.boxConteudo {
  background: $mainColor;
  border-radius: 24px;
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 32px;
  }
  .thumb {
    max-width: 250px;
    margin: 0 auto;
  }
  .categoria a {
    background: transparent;
    color: $accentColor;
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 500;
    padding: 0;
  }
  h2 {
    color: $contrastColor;
    font-size: 2.5em;
  }
  p {
    color: $contrastColor;
    font-size: 1.2em;
    max-width: 500px;
    margin-bottom: 30px;
  }
  .cta {
    padding: 10px 25px;
  }
}
.boxContato {
  background: $mainColor;
  border-radius: 24px;
  h2 {
    color: $contrastColor;
    font-weight: 900;
    margin-bottom: 20px;
    max-width: 300px;
  }
  p {
    color: $contrastColor;
    margin-bottom: 20px;
    max-width: 280px;
  }
  .cta {
    text-transform: uppercase;
    padding: 8px 25px;
  }
  &:first-child {
    background: transparent;
    border: 2px solid $mainColor;
    h2 {
      color: $mainColor;
    }
    p {
      color: $mainColor;
    }
    .cta {
      background: transparent;
      border-color: $mainColor;
    }
  }
}
.boxMaterial {
  background: $materialColor;
  max-width: 49% !important;
  border-radius: 24px;
  transition: ease-in-out 0.2s;
  &:hover {
    background: $mainColor;
    p,
    h2 {
      color: $contrastColor;
    }
  }
  .thumb {
    max-width: 250px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .categoria a {
    background: transparent;
    color: $accentColor;
    font-weight: 600;
  }
  h2,
  p {
    color: $mainColor;
  }
  &:first-child {
    background: $mainColor;
    width: 100% !important;
    max-width: 100% !important;
    flex-direction: row !important;
    align-items: center !important;
    p,
    h2 {
      color: $contrastColor;
      max-width: 500px;
    }
  }
}

.boxPostMain {
  display: flex;
  gap: 64px;
  background-color: #fff;
  border-radius: 32px;
  img {
    max-width: 500px;
    border-radius: 24px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    padding: 24px !important;
    h2 {
      margin-right: 0;
    }
    p {
      margin-right: 0;
    }
  }
}

.tituloPost {
  color: #000;
  font-size: 2em;
  font-weight: 900;
  margin-bottom: 20px;
}
