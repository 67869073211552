@import 'vars';

.shareButtons a{
  transition: ease-in-out .2s;
  border: 2px solid #fff;
}
.shareButtons svg:hover rect{
  fill: none;
}
.shareButtons .fb:hover path{
  fill: #1775f8;
}
.shareButtons .tt:hover path{
  fill: #1e9af0;
}
.shareButtons .ln:hover path{
  fill: #0579b4;
}
.shareButtons .fb:hover{
  border: 2px solid #1775f8;
  border-radius: 5px;
}
.shareButtons .tt:hover{
  border: 2px solid #1e9af0;
  border-radius: 5px;
}
.shareButtons .ln:hover{
  border: 2px solid #0579b4;
  border-radius: 5px;
}