@import 'vars';

.topo{
  background: $mainColor;
}
.secaoCategoria{
  h3{
    text-transform: uppercase;
    color: $mainColor;
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 30px;
  }
}

