@import 'vars';

.skeleton{
  display: block;
  width: 100%;
  background-size: 200% 100%;
  background-color: #eee;
  background-image: linear-gradient(110deg, #ececec 8%, #d1dae3 18%, #ececec 33%);
  animation: 1.5s shine linear infinite;
  margin-bottom: 15px;
  &:not(.image){
    height: 1.5em;
  }  
  &.image{
    padding-top: 60%;
    border-radius: 24px;
  }
}
@keyframes shine {
  0% {
    background-position-X: 0;
  }
  100% {
    background-position-x: -200%;
  }
}