@import "vars";

.secaoInicial {
  background: $mainColor;
  h1 {
    color: $contrastColor;
    font-size: 1em;
    line-height: 1.5em;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  .frasePrincipal {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
  .categoria span {
    color: $accentColor;
    text-transform: uppercase;
  }
  .texto h2 {
    font-size: 2.5em;
    color: $contrastColor;
    font-weight: 900;
    margin: 20px 0;
    max-width: 650px;
  }
  .thumb {
    border-radius: 12px;
  }
  .autor img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
  .autor p {
    margin: 0 10px;
    color: $contrastColor;
    font-size: 0.85em;
    text-decoration: underline;
  }
  .dataPostagem p {
    color: $contrastColor;
    font-size: 0.85em;
    margin-bottom: 0;
  }
}
.maisPosts {
  background: $defaultColor;
  color: $contrastColor;
  width: fit-content;
  border-color: $defaultColor;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.85em;
  padding: 10px 100px;
  transition: ease-in-out 0.2s;
  border-radius: 12px;
  &:hover {
    color: $contrastColor;
    transform: scale(1.05);
  }
}
h2.subtitulo {
  color: $mainColor;
  font-weight: 900;
  text-transform: uppercase;
}

.latestPost {
  padding: 48px 0;
  .py-5 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  h1 {
    margin-bottom: 48px;
  }
}

.LinkTitle {
  color: red !important;
}
