@import '../../styles/vars';

.footer{
  background: $defaultColor;
  .infos img{
    width: 150px;
    margin-bottom: 20px;
  }
  .infos p{
    color: $contrastColor;
    font-size: .85em;
    font-weight: 400;
  }
  .infos a{
    color: $contrastColor;
    font-size: .85em;
    font-weight: 400;
    text-decoration: underline;
  }
  .redesSociais img{
    width: 30px;
    margin: 0 10px;
  }
  .redesSociais img:first-child{
    margin-left: 0;
  }
  .infos .cta{
    width: fit-content;
    border: 2px solid #FFD883;
    color: $defaultColor;
    font-weight: 600;
    text-decoration: none;
  }
  .menu 
    h3{
      color: $contrastColor;
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: 700;
    }
    a{      
      color: $contrastColor;
      margin-bottom: 10px;
      font-weight: 400;
      text-decoration: underline;
    }
    .submenu{
      margin-left: 20px;
    }

}