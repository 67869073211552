@import 'vars.scss';

@font-face {
  font-family: 'Lato';
  src: url(../public/fonts/Lato-Light.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Lato';
  src: url(../public/fonts/Lato-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Lato';
  src: url(../public/fonts/Lato-Regular.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Lato';
  src: url(../public/fonts/Lato-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Lato';
  src: url(../public/fonts/Lato-Black.ttf) format('truetype');
  font-weight: 900;
}

*{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
a{
  text-decoration: none;
}
.nav-link.active{
  color: $accentColor !important;
  opacity: 1;
}

// Estilos form RD

#assinar-newsletter-ff96609754dd4d610913{
  width: 100% !important;
}
#rd-section-knkba721,
#rd-column-knkba727 > div{
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}
#rd-form-knkba728 .bricks-form__input{
  background: transparent !important;
  border: 1px solid #ffd883 !important;
  border-radius: 12px !important;
  color: #fff !important;
  padding: 8px !important;
  width: 100% !important;
}
#rd-form-knkba728 .bricks-form__input::placeholder{
  color: #fff !important;
  display: block !important;
}
#rd-form-knkba728 .bricks-form__label{
  display: none !important;
}
#rd-button-knkba72b{
  background: #ffc544 !important;
  border: 2px solid #ffd883 !important;
  border-radius: 12px !important;
  color: #a0224a !important;
  font-weight: 700 !important;
  padding: 5px 24px !important;
  text-align: center !important;
  text-decoration: none !important;
  transition: .2s ease-in-out !important;
  width: fit-content !important;
  min-width: 0 !important;
  height: auto !important;
  margin-top: 0 !important;
  font-family: Lato,sans-serif !important;
  font-size: 16px !important;
  letter-spacing: 0;
}
#rd-form-knkba728{
  max-width: 100% !important;
  margin: 0 !important;
}
#rd-form-knkba728 .bricks-form__input:not(textarea){
  height: auto !important;
}


.formSidebar #rd-form-knkba728 .bricks-form__input{
  border-color: $defaultColor !important;
  &::placeholder{
    color: $defaultColor !important;
    text-align: left !important;
    padding-left: 10px !important;
  }
}
.formSidebar #rd-button-knkba72b{
  background: $defaultColor !important;
  color: $contrastColor !important;
  border: $contrastColor !important;
}
