@import "vars";

.topoSingle {
  background: $mainColor;
  .categoria a {
    color: $accentColor;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  h1 {
    color: $contrastColor;
    font-weight: 700;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .info,
  .info p {
    color: $contrastColor;
    margin-bottom: 0;
  }
  .autor p {
    margin-right: 5px;
    text-decoration: underline;
  }
  .dataPostagem p {
    margin-left: 5px;
  }
}
.postagem {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.5em;
    font-weight: bolder !important;
    margin: 20px 0 25px 0;
    & span {
      font-weight: bolder !important;
    }
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1.1em;
  }
  h6 {
    font-size: 1em;
  }
  img {
    margin-bottom: 30px;
  }
  a {
    color: #a0224a;
    &:hover {
      color: #a0224a;
    }
  }
}
.sidebar {
  .autor img {
    margin-bottom: 10px;
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }
  h5 {
    font-weight: 600;
  }
}

.secaoNewsletter {
  background-color: #a0224a;
  color: #fff;
  padding: 24px;
  border-radius: 24px;
}
