$mainColor: #A0224A; 
$defaultColor: #000; 
$defaultColorLight: #383838;
$contrastColor: #fff;
$accentColor: #FFC544;
$borderColor: #FFD883;
$casesColor: #CAFF96;
$inboundColor: #D2F0F6;
$conteudoColor: #DFC7FF;
$growthColor: #FFDB96;
$materialColor: #F5F5F5;
