@import 'vars';

.dot {
  background: $materialColor;
  color: $defaultColor;
  padding: 10px 20px;
  border-radius: 12px;
  margin-right: 5px;
  transition: ease-in-out .2s;
  &:last-child{
    margin-right: 0;
  }
  &:hover{
    background: $defaultColor;
    color: $contrastColor;
    cursor: pointer;
  }
}