@import '../../styles/vars';

.menu{
  margin-right: 20px;
}
.navLink {
  color: $contrastColor;
  opacity: .5;
  &:hover{
    color: $accentColor;
  }
}