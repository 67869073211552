@import '../../styles/vars';

.cta {
  background: $accentColor;
  border: 2px solid $borderColor;
  border-radius: 12px;
  padding: 5px 24px;
  text-align: center;
  color: $mainColor;
  text-decoration: none;
  font-weight: 700;
  transition: ease-in-out .2s;
  &:hover{
    color: $mainColor;
    transform: scale(1.05);
  }
  &.primario{
    background: $defaultColor;
    color: $contrastColor;
    &:hover{
      color: $contrastColor;
      transform: scale(1.05);
    }
  }
  &.secundario{
    background: $accentColor;
    color: $contrastColor;
    &:hover{
      color: $contrastColor;
      transform: scale(1.05);
    }
  }
}