@import 'vars';

form{
  width: 100%;
  input{
    width: 100%;
    background: transparent;
    border: 1px solid #FFD883;
    border-radius: 12px;
    padding: 8px;
    color: $contrastColor;
    &::placeholder{
      text-align: center;
      color: $contrastColor;
    }    
    &:focus-visible{
      border: 2px solid $contrastColor;
      outline: none;
    }
  }
  &.model1{
    .cta{
      width: fit-content;
    }
  }
  &.model2{
    input{
      color: $defaultColor;
    }
    .cta{
      background: $defaultColor;
      color: $contrastColor;
      border-color: $defaultColor;
    }
  }
}
.boxNews{
  background: $mainColor;
  border-radius: 24px;
  span{
    color: $accentColor;
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  h3{
    color: $contrastColor;
    font-size: 2.5em;
    font-weight: 900;
  }
  p{
    color: $contrastColor;
    font-size: 1.2em;
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}