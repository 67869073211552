@import 'vars';

.secaoObrigado{
  height: 50vh;
  h1{
    font-weight: 700;
  }
  .cta{
    width: fit-content;
  }
}