@import 'vars';

.searchform{
  width: fit-content;
  input{
    background: transparent;
    color: $contrastColor;
    border: 1px solid $accentColor;
    border-radius: 12px;
    width: 400px;
    height: 40px;
    padding: 5px 20px;
    margin-right: 10px;
  }
  input::placeholder{
    color: $contrastColor;
    font-style: italic;
  }
  button{
    background: #FFC544;
    border: 2px solid #FFD883;
    border-radius: 12px;
    padding: 5px 24px;
    text-align: center;
    color: #A0224A;
    text-decoration: none;
    font-weight: 700;
    transition: ease-in-out 0.2s;
   }
  }