@import '../../styles/vars.scss';

.header {
  background-color: $mainColor;
  width: 100%;
  .container{
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
  .logo img{
    margin-right: 5px;
  }
  .logo{
    color: $contrastColor;
    font-weight: 700;
    text-transform: uppercase;
    span{
      font-weight: 300;
      margin-right: 5px;
    }
  }
  .search{
    margin-right: 20px;
    background: none;
    border: none;
  }
}
